/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import "../App.css";
import Visiontopleft from "../icons/VisionTopLeft";
import { colors } from "../theme";
import { boxStyle } from "../theme";
import Developer from "../icons/developer";
import { handleCalendry } from "../utils/Calendry";
import Navbar from "./Navbar";
import Landing from "../icons/landing";

const MiniAppHeader = (props) => {
  return (
    <div>
      <div
        css={css({
          alignSelf: "flex-start",
          paddingTop: "13px",
          paddingBottom: "13px",
          paddingRight: "20px",
          paddingLeft: "40px",
          position: "fixed",
          top: 0,
          backgroundColor: colors.background,
          zIndex: "99",
          borderBottomRightRadius: "10px",
          "@media (max-width:768px)": {
            width: "100%",
            textAlign: "center",
            borderBottomRightRadius: "13px",
            borderBottomLeftRadius: "13px",
          },
        })}
      >
        <Visiontopleft />
      </div>
      <Navbar />
      <section
        css={css({
          display: "flex",
          flexDirection: "row",
          height: "700px",
          width: "100vw",
          "@media (max-width:768px)": {
            flexDirection: "column-reverse",
            justifyContent: "flex-end",
            paddingTop: props.isAds ? "0" : "18%",
          },
          "@media (min-width: 768px) and (max-width: 990px)": {
            gap: "4rem",
          },
          "@media (min-width: 990px) and (max-width: 1424px)": {
            gap: "3rem",
          },
          "@media (min-width:1536px)": {
            marginBottom: "10%",
          },
          "@media (min-width:1736px)": {
            marginBottom: "13",
          },
          "@media (min-width:2100px)": {
            marginBottom: "15%",
          },
          "@media (min-width:2400px)": {
            marginBottom: "18%",
          },
          "@media (min-width:2600px)": {
            marginBottom: "21%",
          },
          "@media (min-width:2800px)": {
            marginBottom: "25%",
          },
        })}
      >
        <div
          id="left-section"
          css={css({
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            width: "50%",
            "@media (max-width:768px)": {
              width: "100%",
              marginTop: props.isAds ? "-10rem" : "3rem",
            },
            "@media (min-width: 500px) and (max-width: 768px)": {
              marginTop: props.isAds ? "-10rem" : "5rem",
            },
          })}
        >
          <div
            css={css({
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginBottom: "9rem",
              paddingLeft: props.isAds ? "10rem" : "2rem",
              "@media (max-width:768px)": {
                paddingLeft: "0",
                marginBottom: "0",
                maxWidth: "80%",
              },
            })}
          >
            <h1
              css={css({
                fontFamily: "Noto Sans",
                fontSize: "86px",
                fontWeight: "bolder",
                color: colors.primary,
                "@media (max-width:768px)": {
                  fontSize: "50px",
                },
              })}
            >
              MINI APP
            </h1>
            <h2
              css={css({
                fontFamily: "Noto Sans",
                fontSize: "50px",
                fontWeight: "bolder",
                color: colors.primary,
                "@media (max-width:768px)": {
                  fontSize: "36px",
                },
              })}
            >
              Development
            </h2>
            {props.isAds ? (
              <p
                css={css({
                  fontFamily: "Noto Sans",
                  fontSize: "20px",
                })}
              >
                Achieve the best results from your Telegram Ads with our experts guiding you to grow
                on Telegram.
              </p>
            ) : null}
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <button
                type="button"
                onClick={handleCalendry({ path: props.calendlyPath })}
                css={css(boxStyle, {
                  fontSize: "20px",
                  width: "380px",
                  height: "74px",
                  "@media (max-width:768px)": {
                    width: "327px",
                  },
                })}
              >
                Book Your Intro Meeting
              </button>
            </div>
          </div>
        </div>
        <div
          id="right-section"
          css={css({
            width: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            "@media (max-width:768px)": {
              width: "100%",
            },
          })}
        >
          <div
            css={css({
              position: "relative",
              width: "100%",
              height: "100%",
              "@media (max-width:768px)": {
                display: "flex",
                justifyContent: "flex-end",
              },
            })}
          >
            {props.isAds ? (
              <Landing
                css={css({
                  width: "100%",
                  "@media (max-width: 768px)": {
                    width: "70%",
                  },
                  "@media (min-width: 320px) and (max-width: 380px)": {
                    marginTop: props.isAds ? "-8rem" : "",
                  },
                  "@media (min-width: 380px) and (max-width: 490px)": {
                    marginTop: props.isAds ? "-6rem" : "",
                  },
                  "@media (min-width: 490px) and (max-width: 560px)": {
                    marginTop: props.isAds ? "-3rem" : "",
                  },
                  "@media (min-width: 560px) and (max-width: 600px)": {
                    marginTop: props.isAds ? "-2.5rem" : "",
                  },
                  "@media (min-width: 600px) and (max-width: 640px)": {
                    marginTop: props.isAds ? "-2rem" : "",
                  },
                  "@media (min-width: 640px) and (max-width: 768px)": {
                    marginTop: props.isAds ? "-rem" : "",
                  },
                })}
              />
            ) : (
              <>
                <img
                  src="./img/tma.png"
                  width="100%"
                  css={css({
                    objectFit: "contain",
                    "@media (max-width:768px)": {
                      width: "60%",
                      objectFit: "cover",
                    },
                  })}
                  alt="telegram mini app"
                />

                <Developer
                  css={css({
                    position: "absolute",
                    width: "24%",
                    height: "25%",

                    left: "-4%",
                    top: "26%",
                    "@media (max-width:768px)": {
                      width: "38%",
                      height: "38%",
                      left: "22%",
                      top: "32%",
                    },
                    "@media (min-width: 770px) and (max-width: 990px)": {
                      width: "30%",
                      height: "17%",
                      left: "-7%",
                      top: "7%",
                    },
                    "@media (min-width: 990px) and (max-width: 1024px)": {
                      left: "-4%",
                      top: "12%",
                    },
                    "@media (min-width: 1024px) and (max-width: 1280px)": {
                      left: "-4%",
                      top: "16%",
                    },
                    "@media (min-width: 1280px) and (max-width: 1536px)": {
                      left: "-4%",
                      top: "23%",
                    },
                    "@media (min-width:1536px)": {
                      left: "-15%",
                      top: "18%",
                      width: "35%",
                      height: "35%",
                    },
                    "@media (min-width:1950px)": {
                      top: "32%",
                    },
                    "@media (min-width:2336px)": {
                      top: "48%",
                      left: "-12%",
                    },
                    "@media (min-width:2818px)": {
                      top: "63%",
                      left: "-12%",
                    },
                    "@media (min-width:3200px)": {
                      top: "80%",
                      left: "-18%",
                      width: "50%",
                      height: "50%",
                    },
                  })}
                />
                <img
                  src="./img/rocket.gif"
                  alt=""
                  css={css({
                    position: "absolute",
                    maxWidth: "33%",
                    objectFit: "cover",
                    height: "auto",
                    aspectRatio: "1 / 1",
                    borderRadius: "3rem",
                    left: "-16%",
                    top: "54%",
                    "@media (max-width:768px)": {
                      width: "20%",
                      left: "26%",
                      top: "77%",
                      borderRadius: "28px",
                    },
                    "@media (min-width: 770px) and (max-width: 990px)": {
                      width: "34%",
                      left: "-17%",
                      top: "26%",
                      borderRadius: "2rem",
                    },
                    "@media (min-width: 990px) and (max-width: 1024px)": {
                      left: "-16%",
                      top: "36%",
                    },
                    "@media (min-width: 1024px) and (max-width: 1280px)": {
                      left: "-16%",
                      top: "42%",
                    },
                    "@media (min-width: 1280px) and (max-width: 1536px)": {
                      left: "-16%",
                      top: "52%",
                    },
                    "@media (min-width:1536px)": {
                      left: "-19%",
                      top: "58%",
                      width: "45%",
                      borderRadius: "4rem",
                    },
                    "@media (min-width:1950px)": {
                      top: "73%",
                    },
                    "@media (min-width:2336px)": {
                      top: "91%",
                      left: "-19%",
                      borderRadius: "5rem",
                    },
                    "@media (min-width:2818px)": {
                      top: "107%",
                      left: "-21%",
                    },
                    "@media (min-width:3200px)": {
                      top: "143%",
                      left: "-9%",
                      width: "25%",
                      borderRadius: "7rem",
                    },
                  })}
                />
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};
export default MiniAppHeader;
