/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import "./App.css";
import WaveSecond from "./icons/WaveSecond";
import Header from "./components/Header";
import Main from "./components/Main";
import ContactUs from "./components/ContactUs";
import OurGoal from "./components/Ourgoal";
import Team from "./components/Team";
import MyFooter from "./components/MyFooter";

function App() {
  return (
    <div className="App" style={{ minWidth: "100%" }}>
      <Header />
      <Main />
      <WaveSecond
        css={css({
          marginTop: "-60px",
          width: "100%",
          "@media (max-width:768px)": {
            marginTop: "-75px",
          },
        })}
      />
      <ContactUs />
      <OurGoal />
      <Team />
      {/*<section css={css({ backgroundColor: colors.background })}>
        <h2
          css={css({
            fontSize: "52px",
            color: colors.primary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "90px",
            "@media (max-width:768px)": {
              fontSize: "36px",
              fontWeight: "500",
            },
          })}
        >
          Our Clients
        </h2>
        <div
          css={css({
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            paddingTop: "20px",
            paddingBottom: "60px",
            overflowX: "scroll",
            overflowY: "hidden",
            "@media (max-width:768px)": {},
          })}
        >
         <ScrollingCarousel
            css={css({
              svg: {
                "@media (max-width:768px)": {
                  width: "250px",
                  height: "70px",
                },
              },
            })}
          >
            <Clients />
          </ScrollingCarousel>
        </div>
      </section>*/}
      <MyFooter />
    </div>
  );
}

export default App;
