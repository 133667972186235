/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { colors } from "../theme";
const ourTeam = (props) => {
  return (
    <div
      css={css({
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "408px",
        height: "599px",
        paddingBottom: "36px",
        borderRadius: "18px",
        paddingTop: "36px",
        marginBottom: "4em",
        paddingLeft: "10px",
        paddingRight: "10px",
        boxShadow: "0px var(--XS, 8px) 12px 0px rgba(0, 0, 0, 0.10);",
        "@media (max-width:768px)": {
          width: "80%",
          paddingTop: "36px",
          height: "460px",
        },
      })}
    >
      <img
        src={props.src}
        alt="Team Member"
        css={css({
          width: "220px",
          height: "220px",

          "@media (max-width:768px)": {
            width: "150px",
            height: "auto",
            marginTop: "16px",
            paddingTop: "36px",
          },
        })}
      />
      <h3
        css={css({
          color: colors.primary,
          fontWeight: "normal",
          fontSize: "32px",
          letterSpacing: 0,
          paddingTop: "16px",
          "@media (max-width:768px)": {
            fontSize: "24px",
            width: "max-content",
            paddingTop: 0,
          },
        })}
      >
        {props.member}
      </h3>
      <h4
        css={css({
          fontSize: "20px",
          letterSpacing: 0,
          color: colors.lighter,
          fontWeight: "normal",
          "@media (max-width:768px)": {
            fontSize: "20px",
            paddingTop: "0",
          },
        })}
      >
        {props.jobTitle}
      </h4>
      <p
        css={css({
          color: colors.darkText,
          textAlign: "center",
          lineHeight: "32px",
          maxWidth: "370px",
          fontSize: "20px",

          minHeight: "170px",

          letterSpacing: 0,

          "@media (max-width:768px)": {
            fontSize: "16px",
            lineHeight: "24px",
            marginBottom: "32px",
            maxWidth: "255px",
            paddingLeft: "10px",
            paddingRight: "10px",
            paddingTop: 0,
          },
        })}
      >
        {props.desc}
      </p>
    </div>
  );
};
export default ourTeam;
