import * as React from "react";

const Landing = (props) => (
  <svg
    width={821}
    height={693}
    viewBox="0 0 821 693"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_d_1939_1854)">
      <rect x={239.714} y={-125.62} width={688.818} height={694.81} rx={344.409} fill="#2488FF" />
    </g>
    <g filter="url(#filter1_d_1939_1854)">
      <rect
        x={93.0991}
        y={238.534}
        width={404.571}
        height={408.091}
        rx={202.286}
        fill="#2488FF"
        fillOpacity={0.5}
        shapeRendering="crispEdges"
      />
    </g>
    <g filter="url(#filter2_d_1939_1854)">
      <path
        d="M885.291 467.743C830.626 506.832 709.512 554.149 662.376 430.708C603.456 276.407 766.443 137.449 580.432 109.621C394.422 81.7917 421.557 1.7447 442.699 -97.7467"
        stroke="#0AA2D9"
        strokeWidth={28.4444}
      />
    </g>
    <rect
      x={356.945}
      y={174.279}
      width={241.198}
      height={241.198}
      rx={120.599}
      fill="#0F0662"
      fillOpacity={0.49}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M563.044 182.36C557.025 185.788 553.307 192.183 553.307 199.111V245.789C553.307 252.248 556.542 258.279 561.925 261.851L597.058 285.167C604.38 290.027 614.165 284.777 614.165 275.989V172.197C614.165 163.746 605.041 158.442 597.698 162.625L563.044 182.36ZM445.21 222.349C445.21 202.973 460.918 187.265 480.294 187.265H543.24V257.434H480.294C460.918 257.434 445.21 241.726 445.21 222.349ZM500.32 308.225L480.567 268.333H527.003L526.429 302.347C526.184 316.778 506.725 321.159 500.32 308.225Z"
      fill="white"
    />
    <path
      d="M148.752 473.203C211.593 445.25 253.496 426.821 274.463 417.916C334.326 392.494 346.766 388.077 354.874 387.931C356.657 387.9 360.644 388.351 363.227 390.49C365.408 392.297 366.007 394.738 366.294 396.451C366.582 398.164 366.939 402.067 366.655 405.115C363.41 439.917 349.374 524.372 342.232 563.351C339.211 579.844 333.261 585.374 327.501 585.916C314.983 587.091 305.477 577.469 293.353 569.354C274.38 556.657 263.663 548.751 245.247 536.361C223.964 522.041 237.761 514.171 249.89 501.308C253.064 497.942 308.219 446.719 309.286 442.073C309.42 441.492 309.544 439.326 308.283 438.182C307.023 437.039 305.163 437.429 303.82 437.74C301.918 438.181 271.609 458.635 212.895 499.102C204.292 505.134 196.5 508.072 189.518 507.918C181.822 507.749 167.016 503.475 156.01 499.822C142.511 495.341 131.781 492.973 132.715 485.364C133.202 481.401 138.547 477.347 148.752 473.203Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_d_1939_1854"
        x={239.714}
        y={-125.62}
        width={692.373}
        height={698.365}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={1.77778} dy={1.77778} />
        <feGaussianBlur stdDeviation={0.888889} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1939_1854" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1939_1854"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_1939_1854"
        x={93.0991}
        y={238.534}
        width={408.127}
        height={411.646}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={1.77778} dy={1.77778} />
        <feGaussianBlur stdDeviation={0.888889} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1939_1854" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1939_1854"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_1939_1854"
        x={413.446}
        y={-100.703}
        width={483.673}
        height={628.715}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={1.77778} dy={1.77778} />
        <feGaussianBlur stdDeviation={0.888889} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1939_1854" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1939_1854"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export default Landing;
