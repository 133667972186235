/** @jsxImportSource @emotion/react */
import { colors } from "../theme";
import { css } from "@emotion/react";
import OurTeam from "./OurTeam";
import "../App.css";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
const Team = () => {
  return (
    <div css={css({ backgroundColor: colors.background })}>
      <h2
        css={css({
          fontSize: "52px",
          fontWeight: "normal",
          color: colors.primary,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "60px",
          paddingBottom: "12px",
          "@media (max-width:768px)": {
            fontSize: "36px",
          },
        })}
      >
        Our Team
      </h2>
      <div
        css={css({
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "26px",
        })}
      >
        <ScrollingCarousel
          css={css({
            gap: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: { letterSpacing: 0 },
            "> div": {
              gap: "32px",
              paddingBottom: "15px",
              "@media (max-width:768px)": {
                gap: "20px",
                paddingLeft: "20px",
                paddingRight: "20px",
              },
            },
          })}
        >
          <OurTeam
            src="./img/ehsan.png"
            member="Ehsan Nouroddini"
            jobTitle="Web Developer"
            desc="My mind constantly seeks new ways to surpass what seems possible for a product. However, I prioritize optimizing the most impactful aspects and delivering what is needed."
          />
          <OurTeam
            src="./img/soroush.png"
            member="Soroush Azizi"
            jobTitle="UI / UX Desinger"
            desc="My only goal in work is to create an efficient and powerful product for better and more powerful user interaction. Now I’m doing it for almost a decade."
          />
          <OurTeam
            src="./img/alireza.png"
            member="Alireza Ghadiri"
            jobTitle="SEO Specialist"
            desc="Getting organic traffic into highly competitive keywords is not as easy as it was. Still, my job is to make it happen using the newest methods based on the project's marketing budget."
          />
        </ScrollingCarousel>
      </div>
    </div>
  );
};
export default Team;
