/** @jsxImportSource @emotion/react */
import { colors } from "../theme";
import { css } from "@emotion/react";
import "../App.css";
import Services from "./Services";
import Icon from "../icons/design";
import Dev from "../icons/dev";
import Evolution from "../icons/Evolution";
import Security from "../icons/Security";
import Seo from "../icons/Seo";
import Support from "../icons/Script support";
import Navbar from "./Navbar";
import TelegramMiniApp from "../icons/TelegramMiniapp";
import TelegramAds from "../icons/TelegramAds";

const Main = () => {
  return (
    <main
      id="services"
      className="services"
      css={css({
        backgroundColor: colors.primary,
        paddingTop: "90px",
        paddingBottom: "0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        "@media (max-width:768px)": { paddingTop: "75px" },
      })}
    >
      <Navbar />

      <h3
        css={css({
          color: colors.whiteText,
          textAlign: "center",
          fontWeight: "semi-bold",
          fontSize: "52px",
          paddingBottom: "28px",
          marginTop: "24px",
          textShadow: "2px 2px black",
          "@media (max-width:768px)": {
            fontSize: "36px",
          },
        })}
      >
        Our Services
      </h3>

      <div
        css={css({
          display: "flex",
          flexWrap: "wrap",
          maxWidth: "100%",
          width: "1160px",
          alignItems: "center",
          gap: "2rem",
          justifyContent: "space-around",
          "@media (max-width:768px)": {
            flexDirection: "column",
          },
        })}
      >
        <Services
          calendlyPath="/unspecified-category"
          link="/telegram-miniapp"
          icon={<TelegramMiniApp />}
          title="Telegram Mini Apps"
          items={[
            "Design and development",
            "Bot management",
            "Help to reach users",
            "release app in Tapp store",
          ]}
        />
        <Services
          calendlyPath="/unspecified-category"
          link="/telegram-ads"
          icon={<TelegramAds />}
          title="Telegram Ads"
          items={["Campaign management", "In app adds", "Channel adds"]}
        />
        <Services
          calendlyPath="/design"
          icon={<Icon />}
          title="Design"
          items={[
            "User Interface (UI) Design",
            "User Experience (UX) Design",
            "Graphic design",
            "Motion graphic design",
          ]}
        />
        <Services
          calendlyPath="/development"
          icon={<Dev />}
          title="Development"
          items={["Web Portals", "Web Apps", "SaaS"]}
        />
        <Services
          calendlyPath="/design"
          icon={<Evolution />}
          title="Evolution"
          items={["Product Testing", "User Analyze", "Targeting product users"]}
        />
        <Services
          icon={<Security />}
          calendlyPath="/security"
          title="Security"
          items={["Web & Server Security Hardening", "Web Penetration Testing"]}
        />
        <Services
          calendlyPath="/digital-marketing"
          icon={<Seo />}
          title="SEO"
          items={[
            "SEO and ASO Optimization",
            "Content Strategy & Planning",
            "Social Media Marketing",
          ]}
        />
        <Services
          calendlyPath="/unspecified-category"
          icon={<Support />}
          title="Support"
          items={["Python", "PHP", "JavaScript", "Need an Custom Script?"]}
        />
      </div>
    </main>
  );
};
export default Main;
