import MiniAppHeader from "../components/MiniAppHeader";
import { colors } from "../theme";
import WaveSection from "../components/WaveSection";
import ContactUs from "../components/ContactUs";
import OurGoal from "../components/Ourgoal";
import MyFooter from "../components/MyFooter";
import AboutAds from "../components/AboutAds";

const TelegramAds = () => {
  return (
    <div style={{ backgroundColor: colors.background }} id="container">
      <MiniAppHeader isAds />
      <WaveSection isAds />
      <AboutAds />
      <ContactUs />
      <OurGoal />
      <MyFooter />
    </div>
  );
};
export default TelegramAds;
